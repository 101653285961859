import React, { useContext } from "react";
import OverlayFrame from "../components/OverlayFrame";
import { GlobalContext } from "../context/GlobalState";
import Base from "../layout/base";



export default function EmailBestaetigung({
    props
}) {

    const { setBaseInfo, baseInfo } = useContext(GlobalContext);

    return (
        <Base>
            <div className="App h-screen flex items-center justify-center">
                <OverlayFrame>
                    <h2 className="mb-6 text-center text-xl font-extrabold text-white">Prüfe deinen Posteingang</h2>

                    <div className="text-white max-w-prose text-lg text-center">
                        <p>Wir haben dir soeben eine Nachricht mit einem Link zur Aktivierung deines Kontos geschickt.</p>
                    </div>

                    <div className="mt-8 text-white max-w-prose text-center">
                        <p className="font-bold">Du hast keine E-Mail erhalten?</p>
                        <p className="underline cursor-pointer">E-Mail erneut zustellen</p>
                    </div>


                </OverlayFrame>
            </div>
        </Base>
    )
}