import React from "react";
import { GlobalProvider } from "../context/GlobalState";


export default function Base({
    children
}) {
    return (
        <GlobalProvider>
            {children}
        </GlobalProvider>
    )
}