import React, { createContext, useReducer } from 'react';
import { getItem, setItem } from '../managers/storage';
import AppReducer from './AppReducer';
import Types from './Types';

const initialState = {
    feedback: null,
    showContact: false,
    showWorkshop: false,
    showNotification: false,
    showOverlay: false,
    selectedItem: null,
    page: null,
    baseInfo: getItem("base_info") || null,
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    const setFeedback = (feedback) => {
        dispatch({
            type: Types.SET_FEEDBACK,
            payload: feedback
        })
    }

    const setShowContact = (showContact) => {
        dispatch({
            type: Types.SHOW_CONTACT,
            payload: showContact
        })
    }

    const setShowWorkshop = (showWorkshop) => {
        dispatch({
            type: Types.SHOW_WORKSHOP,
            payload: showWorkshop
        })
    }

    const setShowNotification = (showNotification) => {
        dispatch({
            type: Types.SHOW_NOTIFICATION,
            payload: showNotification
        })
    }

    const setSelectedItem = (selectedItem) => {
        dispatch({
            type: Types.SET_SELECTED_ITEM,
            payload: selectedItem
        })
    }

    const setPage = (page) => {
        dispatch({
            type: Types.SET_PAGE,
            payload: page
        })
    }

    const setBaseInfo = (info) => {
        dispatch({
            type: Types.SET_BASE_INFORMATION,
            payload: info
        })
    }

    return (
        <GlobalContext.Provider value={{
            feedback: state.feedback,
            showWorkshop: state.showWorkshop,
            showContact: state.showContact,
            showNotification: state.showNotification,
            selectedItem: state.selectedItem,
            page: state.page,
            baseInfo: state.baseInfo,
            setFeedback,
            setShowContact,
            setShowWorkshop,
            setShowNotification,
            setSelectedItem,
            setPage,
            setBaseInfo,
        }}>
            {children}
        </GlobalContext.Provider>
    )
}