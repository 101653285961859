import React from "react";
import bg from "../images/getyourhouse-bg3.jpg";
import logo from "../images/logos/logo_getyourhouse.svg";


export default function OverlayFrame({
    children,
    bgColor="bg-theme_blue"
}) {
    return (
        <div>
            <div
                className=""
            >

                <div className="relative flex flex-col bg-white justify-center border-2 border-theme_green md:w-[500px]">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md py-8 sm:px-6 lg:px-8">
                        <img
                            className="mx-auto w-[70%] h-auto"
                            src={logo}
                            alt="Workflow"
                        />
                        {/* <p className="mt-2 text-center text-sm text-gray-600">
                        Or{' '}
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                            start your 14-day free trial
                        </a>
                    </p> */}
                    </div>

                    <div className="bg-theme_blue mt-8 w-full">
                        <div className="py-8 px-4 sm:px-10">
                            {children}
                        </div>
                    </div>

                </div>
                <img
                    className="absolute inset-0 w-screen h-screen object-cover -z-10"
                    src={bg}
                />
            </div>
        </div>
    )
}