import React from "react";

const serialize = (data) => {
    return JSON.stringify(data)
}

const deserialize = (str) => {
    return JSON.parse(str)
}


export function setItem(
    key, value
) {
    if (typeof window !== 'undefined') {
        localStorage.setItem(key,
            serialize(value)
        );
    }
}

export function getItem(
    key
) {
    if (typeof window !== 'undefined') {
        return deserialize(
            localStorage.getItem(key)
        );
    }
}