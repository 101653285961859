const Types = {
    "SET_FEEDBACK": "SET_FEEDBACK",
    "SHOW_CONTACT": "SHOW_CONTACT",
    "SHOW_WORKSHOP": "SHOW_WORKSHOP",
    "SHOW_NOTIFICATION": "SHOW_NOTIFICATION",
    "SET_SELECTED_ITEM": "SET_SELECTED_ITEM",
    "SET_PAGE": "SET_PAGE",

    "SET_BASE_INFORMATION": "SET_BASE_INFORMATION"
}

export default Types